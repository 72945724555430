import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section about">
          <h3>About</h3>
          <p>Energy Releaf specializes in innovative solar solutions and BIPV cladding that enhance building efficiency and aesthetics. Our commitment to sustainability is embodied in cutting-edge products like the Exodus micro inverter—pioneering the future of eco-friendly energy systems.</p>
        </div>
        <div className="footer-section contact">
          <h3>Contact</h3>
          <address>
            4928 Cedar Avenue S.<br /> Minneapolis, MN<br />
            <a href="tel:+16124569225">+1 612 456-9225</a><br />
            <a href="mailto:exodus@energyreleaf.com">exodus@energyreleaf.com</a>
          </address>
        </div>
        <div className="footer-section nav">
          <h3>Navigation</h3>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#problem">Problem</a></li>
            <li><a href="#solution">Solution</a></li>
            <li><a href="#contact">Pre-Order Now</a></li>
            <li><a href="#team">Team</a></li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; {new Date().getFullYear()} Energy ReLeaf. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;