import React from 'react';
import './Home.scss'

const Home = () => {
  return (
    <main>
 <section id="home" className="hero">
          <div className="hero-content">
            <h1>Streamlined Installation</h1>
            <p>Building contractors in the Energy ReLeaf Cooperative can sell and install our PV roofing system with existing resources - in half the time of the competition.</p>
            <a href="mailto:exodus@energyreleaf.com" className="cta-button">Contact us</a>
          </div>
          <div className="hero-image">
            <img src="/images/Picture1.jpg" alt="Energy ReLeaf Product" />
          </div>
        </section>
      </main>
  );
};

export default Home;