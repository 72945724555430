import React from 'react';
import './Partners.scss';

const Partners = () => {
  return (
    <section className="partners">
      <div className="partners-container">
        {/* First logo on top */}
        <div className="logo-wrapper">
          <a href="https://renewsource.us" target="_blank" rel="noopener noreferrer">
            <img src="/images/renewsource.png" alt="RenewSource" className="logo" style={{width: '16rem'}} />
          </a>
        </div>
        
        {/* Three logos below */}
        <div className="logo-row">
          <div className="logo-item">
            <a href="https://carlsonschool.umn.edu/mncup/2024MNCupSemis" target="_blank" rel="noopener noreferrer">
              <img src="/images/mncup.png" alt="Minnesota Cup Semifinalist" className="logo" style={{width: '12rem'}} />
            </a>
          </div>
          <div className="logo-item">
            <a href="https://sites.google.com/cleantechopen.org/2024-cto-national-academy-west/home/2024-companies#:~:text=energy%20releaf" target="_blank" rel="noopener noreferrer">
              <img src="/images/regionalwinner1.png" alt="Cleantech open" className="logo" style={{width: '12rem'}} />
            </a>
          </div>
          <div className="logo-item">
            <a href="https://mn.gov/deed/newscenter/press-releases/#/detail/appId/1/id/620622" target="_blank" rel="noopener noreferrer">
              <img src="/images/launchmn.png" alt="Launch Minnesota" className="logo" style={{width: '18rem'}} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;