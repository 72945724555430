import React from 'react';
import './Team.scss'

const Team = () => {
  return (
<section id="team" className="team">
          <div className="container">
            <div className="team-intro">
              <h2>Our Team</h2>
              <p>Our team combines 90 years of experience across solar power, construction, and training. Comprising leading solar community members and startup veterans, our partners and advisors include experts in solar electronics, microgrid engineering, BIPV construction, and business development.</p>
            </div>

            <div className="team-members">
              <TeamMember
                image="/images/peter3.png"
                name="Peter Reese"
                role="Co-Founder"
              />
              <TeamMember
                image="/images/paul3.png"
                name="Paul Reese"
                role="Co-Founder"
              />
              <TeamMember
                image="/images/maria3.png"
                name="Maria Reese"
                role="Co-Founder"
              />
            </div>
          </div>
    </section>
  );
};

function TeamMember({ image, name, role }) {
  return (
    <div className="team-member">
      <img src={image} alt={name} className="team-member-image" />
      <h5>{name}</h5>
      <p>{role}</p>
    </div>
  );
}

export default Team;