import React from 'react';
import './Contact.scss'


const Contact = () => {
  return (
<section id="contact" className="cta" style={{backgroundImage: "url('/images/inverter.jpg')"}}>
          <div className="container">
            <div className="row">
              <div className="cta-content">
                <h2>Order Today</h2>
                <p>Reserve your spot for early access in 2025 to the Exodus – our newest building-integrated hybrid micro inverter. Patent pending, No. 63/609,892.</p>
              </div>
              <div className="cta-button-container">
                <a href="mailto:exodus@energyreleaf.com" className="cta-button outline">Order now</a>
              </div>
            </div>
          </div>
    </section>
  );
};

export default Contact;