import React, { useState } from 'react';
import './Header.scss';
import { BiPhone, BiMenu, BiX } from 'react-icons/bi';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="App-header">
      <nav className="site-nav">
        <div className="container">
          <div className="row">
            <div className="col">
              <a href="/" className="logo">
                <img src="/images/newlogo.png" alt="Energy ReLeaf Logo" />
              </a>
              <ul className={`site-menu ${isMenuOpen ? 'open' : ''}`}>
                <li className="active"><a href="#home">Home</a></li>
                <li><a href="#problem">Problem</a></li>
                <li><a href="#solution">Solution</a></li>
                <li><a href="#contact">Pre-Order Now</a></li>
                <li><a href="#team">Team</a></li>
              </ul>
              <a href="tel:+16124569225" className="call-us">
                <BiPhone /><span>+1 612 456-9225</span>
              </a>
            </div>
            <button 
              className="burger-menu" 
              onClick={() => setIsMenuOpen(!isMenuOpen)} 
              aria-label="Toggle navigation menu"
            >
              {isMenuOpen ? <BiX /> : <BiMenu />}
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;