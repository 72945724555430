import React from 'react';
import { BiBrightnessHalf, BiCloud, BiHome, BiFileBlank, BiWrench, BiBuilding} from 'react-icons/bi';
import './Problem.scss'

const Problem = () => {
  return (
<section id="problem" className="sec-features">
          <div className="container">
            <h2 className="text-center mb-4">What problems does our product solve?</h2>
            <p className="p1">Half the 5 million homeowners who need new roofs every year want solar, and Energy ReLeaf is the only manufacturer who makes a PV shingle system a roofer can install safely without becoming an electrician.</p>
            <div className="row">
              <ProblemItem
                icon={<BiBrightnessHalf />}
                title="Rising Energy Costs"
                description="Increased energy demand and prices impact consumers and businesses."
              />
              <ProblemItem
                icon={<BiCloud />}
                title="Carbon Emissions"
                description="Environmental impact due to high carbon footprints of energy production."
              />
              <ProblemItem
                icon={<BiHome />}
                title="Roof Penetrations and Structural Issues"
                description="Structural vulnerabilities and roof penetrations can increase maintenance costs and compromise building safety."
              />
              <ProblemItem
                icon={<BiFileBlank />}
                title="Increasing Soft Costs"
                description="Rising costs related to permits, grid connections, and non-technical labor intensify project expenses and timelines."
              />
              <ProblemItem
                icon={<BiWrench />}
                title="Constructability Concerns"
                description="Complex site conditions and regulatory hurdles impact the feasibility and execution of construction projects."
              />
              <ProblemItem
                icon={<BiBuilding />}
                title="Jurisdictional Biases"
                description="Local regulatory biases can hinder the adoption of independent energy solutions and self-sufficiency."
              />
            </div>
          </div>
        </section>
  );
};

function ProblemItem({ icon, title, description }) {
  return (
    <div className="feature">
      <span className="icon">{icon}</span>
      <div className="content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Problem;