import React from 'react';
import './GoogleForm.scss';

const GoogleForm = () => {
  const googleFormUrl = 'https://forms.gle/8NUhjn3vMAk9Xh439';

  return (
    <div className="info-request-container">
      <button 
        onClick={() => window.open(googleFormUrl, '_blank')} 
        className="info-request-button"
      >
        Download Data Sheets
      </button>
    </div>
  );
};

export default GoogleForm;