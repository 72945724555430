import React from 'react';
import './App.scss';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Problem from './components/Problem/Problem';
import Solution from './components/Solution/Solution';
import Contact from './components/Contact/Contact';
import Team from './components/Team/Team';
import Partners from './components/Partners/Partners';
import Footer from './components/Footer/Footer';
import GoogleForm from './components/GoogleForm/GoogleForm';

function App() {
  return (
    <div>
      < Header/>
      < Home/>
      < Problem/>
      < Solution/>
      < Contact/>
      < GoogleForm/>
      < Team/>
      < Partners/>
      < Footer />
    </div>
  );
}



export default App;
