import React from 'react';
import './Solution.scss'
import {BiSun, BiCog, BiPlug, BiRecycle} from 'react-icons/bi';


const Solution = () => {
  return (
<section id="solution" className="solution">
          <div className="container">
            <div className="row">
              <div className="solution-image">
                <img src="/images/bipv.jpg" alt="BIPV Cladding" />
              </div>
              <div className="solution-content">
                <h2>Our Solution: BIPV Cladding</h2>
                <SolutionItem
                  icon={<BiRecycle />}
                  title="100% recyclable"
                  description={[
                    "Every roof keeps hundreds of tons of CO2 out of the atmosphere and at least 5 tons of asphalt and construction waste out of the landfill.",
                  ]}
                />
                <SolutionItem
                  icon={<BiSun />}
                  title="50 Year Cladding: interlocking, overlapping, thermo-conductive, high refractive index"
                  description={[
                    "Energy Shakes (NEC 690.12 compliant arrays, touch-safe connectors).",
                    "Aesthetic Shakes for richest full roof look (slate, cedar, elan)",
                    "Transitional Shakes for cost saving integration with asphalt composite, others"
                  ]}
                />
                <SolutionItem
                  icon={<BiCog />}
                  title="Micro Inverter: installed with Cladding"
                  description={[
                    "Climate Controlled, readily accessible by electricians and inspectors",
                    "Integrated energy storage for grid ride through and peak management"
                  ]}
                />
                <SolutionItem
                  icon={<BiPlug />}
                  title="Interconnection Module: 3phase or single/split"
                  description={[
                    "NEC 702 Optional Standby System (default)",
                    "Integrated AC Disconnect",
                    "Critical Load Serving (Off-grid or islanding)",
                    "DIY friendly for off-grid applications (touch-safe, pre-wired receptacles)"
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
  );
};

function SolutionItem({ icon, title, description }) {
  return (
    <div className="solution-item">
      <span className="icon">{icon}</span>
      <div className="content">
        <h3>{title}</h3>
        <ul>
          {description.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Solution;